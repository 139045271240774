.slick-prev, .slick-next {
    width: 40px;
    height: 40px;
    z-index: 1;
    background-color: #A60321;
    border-radius: 0%;
  }
  
  .slick-prev::before, .slick-next::before {
    font-size: 20px;
    
  }
  
  .slick-dots li button:before {
    font-size: 10px;
    color: #000000;
  }

  .thumbnail-container {
    position: relative;
    width: 100%;
    padding-bottom: 54.25%; 
    overflow: hidden;
  }
  
  .thumbnail-container img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .bg-clip-text {
    -webkit-background-clip: text !important;
    
  }
  